import React from "react";
import styled from 'styled-components';
const StyledGrid = styled.div`
  perspective: 500px;
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;

  div {
    background: repeating-linear-gradient(0deg,rgb(72 193 84 / 50%) 5px,transparent 10px,transparent 60px),repeating-linear-gradient(90deg,rgb(72 193 84 / 50%) 5px,transparent 10px,transparent 120px,rgb(72 193 84 / 50%) 122px);
    height: 600vh;
    transform-origin: bottom center;
    width: 300vw;
    opacity: .5;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: rotateX(70deg) translateX(-50%);
  }
`;
const Grid = ({...props}) => {
  return <StyledGrid {...props}><div></div></StyledGrid>;
}
export default Grid;