import { DefaultTheme } from 'styled-components';

const themeData: DefaultTheme = {
  background: "hsla(291, 27%, 44%, 1)",
  backgroundDark: "hsla(291, 27%, 24%, 1)",
  footer: "#2a2a2a",
  text: "rgb(232 251 137)",
  links: "rgb(240 159 255)",
  hover: "hsla(158, 49%, 39%, 1)",
  accent: "rgb(192 107 209)",
  titleFont: "'Bungee Hairline'",
  bodyFont: "'VT323', monospace;",
  mediaQuery: {
    s500: "@media screen and (min-width: 500px)",
    s700: "@media screen and (min-width: 700px)"
  }
};
export default themeData;