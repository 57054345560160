import React from "react";
import { Helmet } from "react-helmet";
import styled, {ThemeProvider} from "styled-components";
import {Normalize} from "styled-normalize";
import GlobalStyle from './global/globalStyle';
import themeData from './global/theme';
import Grid from "./components/grid";
interface LayoutProps {
  children?: React.ReactElement|React.ReactElement[];
}
const StyledWrapper = styled.main`
  background: #2a2a2a;
  color: ${({theme}) => theme.text};
  font-family: ${({theme}) => theme.bodyFont};
  width: 100%;
  padding: 80px 0 0;
  text-align: center;
`;
const Noise = styled.div`
    background: repeating-linear-gradient(0deg,#424242 1px,transparent 6px);
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: repeat-y;
    width: 100%;
`;

const Layout = ({children}:LayoutProps) => (
  <ThemeProvider theme={themeData}>
    <Normalize />
    <GlobalStyle />
    <Helmet>
      <title>Bjørn Mann</title>
      <meta name="description" content="Bjørn Mann does internet for the world, you'll love it." />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <link rel="canonical" href="http://www.bjornmann.com/" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Bungee+Hairline&family=Mr+Dafoe&family=VT323&display=swap" rel="stylesheet" />
    </Helmet>
    <Noise />
    <StyledWrapper>
      {children}
      <Grid />
    </StyledWrapper>
  </ThemeProvider>
)
export default Layout;