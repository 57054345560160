import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root{
  --color-skin:#DBBDBB;
  --color-hair:#46312A;
  --color-neck-beard:#90837F;
  --color-lips:#A3626D;
  --color-eyes:#374872;
  --color-eye-whites:#F4EFEA;
  --color-ear-shadow:#BBA8A6;
  --color-sun-line:#ff00d1;
}
html,
body,
#___gatsby,
#gatsby-focus-wrapper {
  min-height: 100vh;
  background:${({theme}) => theme.footer};
}
#___gatsby{
  position: relative;
}
::selection {
  background: ${({theme}) => theme.text};
  color:${({theme}) => theme.background};
}
h2{
  font-size: 3rem;
}
h3{
  font-size: 2rem;
}
h4{
  font-size: 2rem;
}
h5{
  font-size: 1rem;
}
h6{
  font-size: 1rem;
}
`;

export default GlobalStyle